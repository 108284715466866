import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, catchError, tap } from 'rxjs';
import { ICompanyInfo } from 'src/app/model/interfaces/company-info.interface';
import { IGroup } from 'src/app/model/interfaces/group.interface';
import {
  IResponse,
  IResponseData,
  IResponseMessage,
  ResponseData,
  ResponseDataCode,
  ResponseDataMessageError,
  ResponseDataOrMessage
} from 'src/app/model/interfaces/response.interface';
import {
  DynamicDepartmentRules,
  FilterO365Users,
  PreviewDepartmentRuleUsers,
  RulesCreateOrEdit,
  UserImportRules
} from 'src/app/modules/o365/model/interfaces/o365-rules.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { AlertService } from 'src/app/services/alert/alert.service';
import { IEmployeeGet } from 'src/app/services/employee/employee-service.interface';
import {
  AzureGroupResponse,
  CRMError,
  O365AccessToken,
  O365Administrator,
  O365CloudToUse,
  O365Rules,
  O365Users,
  SyncUsersFromEndpointResponse
} from './azure-sync.interface';
import {
  AddDomainForm,
  O365ReroutingConnection,
  O365ReroutingConnectionDomain,
  O365ReroutingConnectionDomainListWithValidation,
  O365ReroutingConnectionDomainListWithCount,
  ReroutingConnectionSetupForm
} from '@modules/o365-rerouting/model/interfaces/o365-rerouting.interface';
import { QueryHelperService } from '@services/query-helper/query-helper.service';
import { IQueryObj } from '@services/query-helper/query-helper-service.interface';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { intersects } from 'radash';
import { BLOCKED_DOMAIN_LIST } from '@modules/o365-rerouting/model/const/o365-rerouting.const';
import { IntercomService } from '@services/intercom/intercom.service';
import { O365_FIELDS_DATA, O365_MAPPING_FIELDS_DATA } from '@modules/o365/model/const/o365-preferences.const';
import {
  O365ExtensionProperty,
  O365MappingFieldsData,
  StaticFieldsData
} from '@modules/o365/model/interfaces/o365-preferences.interface';

@Injectable({
  providedIn: 'root'
})
export class AzureSyncService {
  private alert = inject(AlertService);
  private http = inject(HttpClient);
  private intercomService = inject(IntercomService);
  private navigationSidebarService = inject(NavigationSidebarService);
  private operator = inject(CustomOperators);
  private queryHelperService = inject(QueryHelperService);

  /**
   * Extract the data object that used with response types `ObjectDataOrMessage<T>`
   * @returns Data of api response
   */
  extractResponseObjectDataOrMessage(): <T>(source: Observable<ResponseDataOrMessage<T>>) => Observable<T> {
    return <T>(source: Observable<ResponseDataOrMessage<T>>) => {
      return source.pipe(
        map(value => {
          if (value.message) {
            void this.alert.defaultErrorMessage(value.message);
          } else if (!value.success) {
            void this.alert.defaultErrorMessage(this.alert.translate('TECHNISCHER_FEHLER'));
          }
          return value.data ? value.data : ({} as T);
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
    };
  }

  /**
   * Extract the data array that used with response types `ArrayDataOrMessage<T>`
   * @returns Data of api response
   */
  extractResponseArrayDataOrMessage(): <T>(source: Observable<ResponseDataOrMessage<T>>) => Observable<T> {
    return <T>(source: Observable<ResponseDataOrMessage<T>>) => {
      return source.pipe(
        map(value => {
          if (value.message) {
            void this.alert.defaultErrorMessage(value.message);
          } else if (!value.success) {
            void this.alert.defaultErrorMessage(this.alert.translate('TECHNISCHER_FEHLER'));
          }
          return value.data ? value.data : ([] as T);
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
    };
  }
  /* Prepare object for directory attribute field to mapped with mailtastic field
   * @param key - Field key string
   * @param isMapped - Mapped boolean
   * @returns Object of field
   */
  prepareDirectoryExtensionAttributeObj(key: string, isMapped: boolean): StaticFieldsData {
    return {
      example: 'directory-attribute',
      isMapped,
      key,
      translateKey: 'extension'
    };
  }

  /**
   * Mapped o365 data field as per DB syncSettings if exist
   * @param syncMappedData - Mapped field exist on DB object
   * @returns Array of fields
   */
  convertSyncSettingsToO365Mapping(syncMappedData: { [key: string]: string }): O365MappingFieldsData[] {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    const dataMapping: O365MappingFieldsData[] = structuredClone(O365_MAPPING_FIELDS_DATA);
    const dataFields: StaticFieldsData[] = structuredClone(O365_FIELDS_DATA);
    /* eslint-enable @typescript-eslint/no-unsafe-assignment */

    Object.keys(syncMappedData).forEach((key: string) => {
      if (key.startsWith('notSyncOnly')) {
        dataMapping.push({
          backendField: '',
          dataField: null,
          editable: false,
          field: key,
          inputPlaceholderKey: '',
          required: false,
          translation: key
        });
      }

      // Find actual field to add mapped data
      const mapObj = dataMapping.find(data => data.field === key);
      if (mapObj) {
        // Find and assing apped info
        if (syncMappedData[key].includes('onPremisesExtensionAttributes')) {
          mapObj.dataField = {
            example: 'extension-attribute',
            isMapped: true,
            key: syncMappedData[key],
            translateKey: 'extension-attribute'
          };
        } else if (syncMappedData[key].startsWith('extension_')) {
          const splittedKey = syncMappedData[key].split('_');
          const o365DataField = dataFields.find(info => info.key === splittedKey[2]);
          if (o365DataField) {
            mapObj.dataField = o365DataField;
          } else {
            mapObj.dataField = this.prepareDirectoryExtensionAttributeObj(splittedKey[2], true);
          }
          mapObj.dataField.key = syncMappedData[key];
        } else {
          mapObj.dataField = dataFields.find(info => info.key === syncMappedData[key]) || null;
        }
      }
    });
    return dataMapping;
  }

  /**
   * Syncs employee details data like phone and address
   * @param syncInfoId - Connection id
   * @returns Observable of sync user ids
   */
  syncUsersDetailDataAll(syncInfoId: number): Observable<SyncUsersFromEndpointResponse> {
    return this.http
      .put<IResponseData<SyncUsersFromEndpointResponse>>('/azure/sync/userdata/all', { syncInfoId: syncInfoId })
      .pipe(
        map(value => {
          if (!value.success) {
            throw new Error(void this.alert.defaultErrorMessage(this.alert.translate('DATEN_NICHT_GELADEN')));
          }
          return value.data;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * DELETE THIS FUNCTION ? NO USAGE
   * Syncs the list of employees with the detail data
   * @param users -
   * @returns
   */
  syncUsersDetailData(users: IEmployeeGet[]): unknown {
    return this.http.put('/azure/sync/userdata', { users }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the auth url
   * @param cloudToUse - Selected platform
   * @returns - Url string
   */
  getAuthUrl(cloudToUse: O365CloudToUse): Observable<string> {
    return this.http
      .get<IResponseData<string>>(`/azure/authurl/${cloudToUse}/true`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the auth url for ews
   * @returns
   */
  getAuthUrlOWA(): unknown {
    return this.http.get('/azure/authurlOWA').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks the notifications
   * @returns
   */
  checkNotifications(): unknown {
    return this.http.get('/azure/checkNotifications').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks if the notifications have been seen
   * @returns
   */
  notificationsHasBeenSeen(): unknown {
    return this.http.get('/azure/notificationsHasBeenSeen').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the connection info
   * @returns Observable of connection
   */
  getConnectionInfo(): Observable<ICompanyInfo> {
    return this.http
      .get<ResponseDataOrMessage<ICompanyInfo>>('/azure/connectioninfo')
      .pipe(this.extractResponseObjectDataOrMessage());
  }

  /**
   * Removes the tokens and connection info
   * @param syncInfoId - Connection id
   * @returns Observable of success
   */
  deleteConnectionInfo(syncInfoId: number): Observable<true> {
    return this.http.delete<IResponse>(`/azure/connectioninfo/${syncInfoId}`).pipe(
      this.operator.extractResponse(),
      tap(() => {
        this.navigationSidebarService.loadMyIntegrations();
        this.intercomService.updateInfo({
          m365_integration: false
        });
      })
    );
  }

  /**
   * Update the connection info
   * @param connectionInfo - Connection object
   * @returns Observable of company info
   */
  updateConnectionInfo(connectionInfo: Partial<ICompanyInfo>): Observable<ICompanyInfo> {
    return this.http
      .put<ResponseData<ICompanyInfo>>('/azure/connectioninfo', { connectionInfo })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Stores the Azure credentials
   * @param accessData - Connection object
   * @returns Observable of success
   */
  storeAzureCredentials(accessData: Partial<ICompanyInfo>): Observable<true> {
    return this.http.post<IResponse>('/azure/credentials', { accessData }).pipe(
      this.operator.extractResponse(),
      tap(() =>
        this.intercomService.updateInfo(
          {
            m365_integration: true
          },
          true
        )
      )
    );
  }

  /**
   * Update the Azure credentials
   * @param integrationConnection - connection object
   * @returns Observable of  success
   */
  updateIntegrationConnection(integrationConnection: Partial<ICompanyInfo>): Observable<true> {
    return this.http
      .put<IResponse>('/azure/integrationConnection', { integrationConnection })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Gets the user list and displays it the on frontend.
   * @param cloudToUse -
   * @param token -
   * @returns
   */
  getUserList(cloudToUse: unknown, token: unknown): unknown {
    return this.http
      .post('/azure/listusers', { azuretoken: token, cloudToUse })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Flags all azure users as `activeSync`.
   * Is needed when user enables the auto manage user flag becaue all employees will then be active.
   * @returns
   */
  setAllAzureUsersActiveSync(): unknown {
    return this.http.put('/azure/setallactivesync', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks the credentials
   * @param token -
   * @param cloud -
   * @param refreshToken -
   * @returns
   */
  checkCredentials(token: unknown, cloud: unknown, refreshToken: unknown): unknown {
    const obj = {
      accessData: {
        token: token,
        cloud: cloud,
        refreshToken: refreshToken
      }
    };
    return this.http.post('/azure/checkconnection', obj).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the user sign-in data
   * @returns
   */
  userSignin(): unknown {
    return this.http.get('/azure/signin').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the value of token to be used for getting user list
   * @param azureCode - Connection code string from return url
   * @param cloudToUse - Selected platform
   * @returns Observable of o365 access token object
   */
  getToken(azureCode: string, cloudToUse: O365CloudToUse): Observable<O365AccessToken> {
    return this.http
      .post<IResponseData<O365AccessToken>>('/azure/getAccessToken', {
        azurecode: azureCode,
        cloudToUse,
        isFromNewAngularTs: true
      })
      .pipe(
        map(value => {
          if (!value.data) throw new Error(void this.alert.defaultErrorMessage(this.alert.translateDataNotLoaded()));
          return value.data;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Gets the value of token to be used for getting user list
   * @param azureCode -
   * @param cloudToUse -
   * @returns
   */
  getTokenOWA(azureCode: unknown, cloudToUse: unknown): unknown {
    return this.http
      .post('/azure/getAccessTokenOWA', { azurecode: azureCode, cloudToUse })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the OWA data
   * @returns
   */
  getOwaData(): unknown {
    return this.http.get('/azure/owadata').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sets the OWA data
   * @param data -
   * @returns
   */
  setOwaData(data: unknown): unknown {
    return this.http.post('/azure/owadata', data).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Changes the OWA settings
   * @param data -
   * @returns
   */
  changeOwaSettings(data: unknown): unknown {
    return this.http.put('/azure/owadata/settings', data).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Removes the OWA data
   * @param data -
   * @returns
   */
  removeOwaData(data: any): unknown {
    return this.http.delete('/azure/owadata', data).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Pushes all signatures
   * @returns
   */
  pushAllSignatures(): unknown {
    return this.http.post('/azure/owadata/pushallsignatures', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Creates an o365 domain config
   * @param data -
   * @returns
   */
  o365createDomainConfig(data: unknown): unknown {
    return this.http.post('/azure/rerouting/domainconfig', data).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the o365 domain config
   * @returns
   */
  o365getDomainConfig(): unknown {
    return this.http.get('/azure/rerouting/domainconfig').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the o365 domain config by id
   * @param id -
   * @returns
   */
  o365getDomainConfigById(id: string): unknown {
    return this.http.get(`/azure/rerouting/domainconfigById/${id}`).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Decrypts the list and groups of o365
   * @param data -
   * @param decrypt -
   * @returns
   */
  o365listUserAndGroups(data: unknown, decrypt: unknown): unknown {
    return this.http
      .post('/azure/rerouting/listUserAndGroups', { data: data, decrypt: decrypt })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Decrypts the list and groups of o365
   * @param data -
   * @param decrypt -
   * @returns
   */
  checkCredentialsAndReadConnector(data: unknown, decrypt: unknown): unknown {
    return this.http
      .post('/azure/rerouting/checkCredentialsAndReadConnector', { data: data, decrypt: decrypt })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Decrypts the list and groups of o365
   * @param data -
   * @param decrypt -
   * @returns
   */
  o365updateCredentialsForAllDomains(data: unknown, decrypt: unknown): unknown {
    return this.http
      .post('/azure/rerouting/updateCredentialsForAllDomains', { data: data, decrypt: decrypt })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Updates the o365 domain config
   * @param config -
   * @param id -
   * @returns
   */
  o365updateDomainConfig(config: unknown, id: string): unknown {
    return this.http
      .put('/azure/rerouting/changeReroutingSetting', { settings: config, id })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Changes the status for the groups
   * @param groups -
   * @param settings -
   * @returns
   */
  changeGroupStatus(groups: IGroup[], settings: unknown): unknown {
    return this.http
      .put('/azure/rerouting/changeGroupSetting/', { groups, settings })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   *
   * @param domain -
   * @returns
   */
  o365checkIfDomainExists(domain: string): unknown {
    return this.http.get(`/azure/rerouting/checkIfDomainExists/${domain}`).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sets the config for all
   * @param title -
   * @param setting -
   * @returns
   */
  setAllConfig(title: string, setting: unknown): unknown {
    return this.http
      .put('/azure/rerouting/setAllConfig', { setting, title })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Deletes the specific 0365 domain config
   * @param id -
   * @returns
   */
  o365deleteDomainConfig(id: string): unknown {
    return this.http.delete(`/azure/rerouting/domainconfig/${id}`).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Connects with o365
   * @returns
   */
  o365Connect(): unknown {
    return this.http.get('/azure/rerouting/connect').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks the powershell logs
   * @returns
   */
  checkPSLogs(): unknown {
    return this.http.get('/azure/rerouting/psLogs').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Updates the seen powershell logs
   * @returns
   */
  updateSeenLogs(): unknown {
    return this.http.get('/azure/rerouting/seenPsLogs').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Removes o365 rule
   * @param rule - Rule object
   * @returns Observable of success
   */
  deleteRule(rule: O365Rules): Observable<IResponseMessage> {
    return this.http
      .delete<IResponseMessage>(`/azure/rule/${rule.integrationConnection}/${rule.id}`)
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Get o365 rules
   * @param syncInfoId - Connection id
   * @returns Observable of rules list
   */
  getO365Rules(syncInfoId: number): Observable<O365Rules[]> {
    return this.http
      .get<IResponseData<O365Rules[]>>(`/azure/getO365Rules/${syncInfoId}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get O365 rule as given id
   * @param ruleId - O365 rule id
   * @returns - Observable of o365 rule
   */
  getO365Rule(ruleId: string): Observable<O365Rules> {
    return this.http
      .get<IResponseData<O365Rules>>(`/azure/getO365Rule/${ruleId}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get o365 administrator details
   * @param cloudToUse - Selected platform
   * @param accessTokenKey - Connection token
   * @returns - Observable of administrator data
   */
  getO365Administrator(cloudToUse: O365CloudToUse, accessTokenKey: string): Observable<O365Administrator> {
    return this.http
      .post<ResponseDataOrMessage<O365Administrator>>(`/azure/administrator`, {
        cloudToUse: cloudToUse,
        azuretoken: accessTokenKey
      })
      .pipe(this.extractResponseObjectDataOrMessage());
  }

  /**
   * Get o365 users from created rules
   * @param syncInfoId - Connection id
   * @returns Observable of users list
   */
  getO365UsersFromRules(syncInfoId: number): Observable<O365Users[]> {
    return this.http
      .get<ResponseDataOrMessage<O365Users[]>>(`/azure/getO365UsersFromRules/${syncInfoId}`)
      .pipe(this.extractResponseArrayDataOrMessage());
  }

  /**
   * Get o365 connection
   * @param syncInfoId - Connection id
   * @returns Observable of connection
   */
  getO365Connection(syncInfoId: number): Observable<{ companyInfo: ICompanyInfo; error?: CRMError }> {
    return this.http.get<ResponseDataMessageError<ICompanyInfo>>(`/azure/o365Connection/${syncInfoId}`).pipe(
      map(value => {
        const companyInfo = value.data === null ? ({} as ICompanyInfo) : value.data;
        if (value.success) {
          return { companyInfo };
        } else if (value.error) {
          const errorRespose = JSON.parse(value.error) as CRMError;
          if (errorRespose.error === 'invalid_grant') {
            companyInfo.withError = true;
            companyInfo.connectionError = true;
            return { companyInfo, error: errorRespose };
          }
        } else {
          void this.alert.defaultErrorMessage(value.message || this.alert.translate('TECHNISCHER_FEHLER'));
        }
        return { companyInfo };
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Update o365 connection details
   * @param connection - Connection object
   * @returns Observable of success
   */
  updateO365Connection(connection: ICompanyInfo): Observable<true> {
    return this.http
      .put<ResponseDataCode>('/azure/integrationConnection', { integrationConnection: connection })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Get O365 connections list
   * @returns - Observable of connections list
   */
  getO365Connections(): Observable<ICompanyInfo[]> {
    return this.http
      .get<IResponseData<ICompanyInfo[]>>('/azure/o365Connections')
      .pipe(this.extractResponseArrayDataOrMessage());
  }

  /**
   * Get O365 groups list
   * @param syncInfoId - Connection id
   * @returns - Observable of groups list
   */
  getO365Groups(syncInfoId: number): Observable<AzureGroupResponse[]> {
    return this.http
      .get<IResponseData<AzureGroupResponse[]>>(`/azure/getGroups/${syncInfoId}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get O365 groups list
   * @param syncInfoId - Connection id
   * @param keyword - Search text
   * @returns - Observable of groups list
   */
  getO365GroupsByKeyword(syncInfoId: number, keyword: string): Observable<AzureGroupResponse[]> {
    return this.http
      .post<IResponseData<AzureGroupResponse[]>>(`/azure/getGroupsByKeyword`, { syncInfoId, keyword })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Filter o365 user list as defined rules
   * @param conjunction - Condition name `and` or `or`
   * @param rules - Array of object of applied rules
   * @param syncInfoId - Connection id
   * @returns List of filter o365 users
   */
  filterO365Users(conjunction: string, rules: UserImportRules[], syncInfoId: number): Observable<FilterO365Users[]> {
    return this.http
      .post<IResponseData<FilterO365Users[]>>('/azure/filterO365Users', {
        conjunction: conjunction,
        rules: rules,
        syncInfoId: syncInfoId,
        type: 'basic'
      })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Used to get dynamic department assignment rules
   * @param syncInfoId - Connection id
   * @param dynamicRule - Rule
   * @param created - Rule is created or not
   * @returns - Observable of preview department rules users
   */
  getDynamicDepartments(
    syncInfoId: number,
    dynamicRule: DynamicDepartmentRules,
    created: boolean,
    users: FilterO365Users[] = []
  ): Observable<PreviewDepartmentRuleUsers[]> {
    return this.http
      .post<IResponseData<PreviewDepartmentRuleUsers[]>>('/azure/getDynamicDepartments', {
        created,
        dynamicRule,
        syncInfoId,
        users
      })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Create rule of o365
   * @param rule - Rule object
   * @returns - Observable of success
   */
  createRule(rule: RulesCreateOrEdit): Observable<true> {
    return this.http.post<IResponse>('/azure/createRule', rule).pipe(this.operator.extractResponse());
  }

  /**
   * Update rule details of o365
   * @param id - Rule id
   * @param rule - Rule object
   * @returns - Observable of success
   */
  updateRule(id: string, rule: RulesCreateOrEdit): Observable<true> {
    return this.http.put<IResponse>(`/azure/updateRule/${id}`, rule).pipe(this.operator.extractResponse());
  }

  /**
   * Get o365 users from created rules
   * @returns Observable of users list
   */
  getO365Users(): Observable<O365Users[]> {
    return this.http
      .get<ResponseDataOrMessage<O365Users[]>>(`/azure/o365Users`)
      .pipe(this.extractResponseArrayDataOrMessage());
  }

  /**
   * Add rerouting connection
   * @param data - Object of `ReroutingConnectionSetupForm`
   * @returns Observable of `ICompanyInfo`
   */
  addReroutingConnection(data: ReroutingConnectionSetupForm): Observable<ICompanyInfo> {
    return this.http
      .post<ResponseData<ICompanyInfo>>('/azure/rerouting/connection', data)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Update rerouting connection
   * @param data - Object of `ReroutingConnectionSetupForm`
   * @param syncInfoId - Id of connection
   * @returns Observable of `ICompanyInfo`
   */
  updateReroutingConnection(data: ReroutingConnectionSetupForm, syncInfoId: number): Observable<ICompanyInfo> {
    return this.http
      .put<ResponseData<ICompanyInfo>>(`/azure/rerouting/connection/${syncInfoId}`, data)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets list of connection
   * @returns - Array of `O365ReroutingConnection`
   */
  getReroutingConnection(): Observable<O365ReroutingConnection[]> {
    return this.http
      .get<ResponseData<O365ReroutingConnection[]>>('/azure/rerouting/getConnection')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get details of connection
   * @param syncInfoId - Id of connection
   * @returns Object of `O365ReroutingConnection`
   */
  getReroutingConnectionDetails(syncInfoId: number): Observable<O365ReroutingConnection> {
    return this.http
      .get<ResponseData<O365ReroutingConnection>>(`/azure/rerouting/connection/${syncInfoId}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Add domain data
   * @param data - Form data
   * @returns Added domain data object of `O365ReroutingConnectionDomain`
   */
  addReroutingConnectionDomain(data: AddDomainForm): Observable<O365ReroutingConnectionDomain> {
    return this.http
      .post<ResponseData<O365ReroutingConnectionDomain>>(`/azure/rerouting/domain`, data)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Working on uodate domain data
   * @param id - Id of domain data
   * @param data - Object of `AddDomainForm`
   * @returns Object of `O365ReroutingConnectionDomain`
   */
  updateReroutingConnectionDomain(id: string, data: AddDomainForm): Observable<O365ReroutingConnectionDomain> {
    return this.http
      .put<ResponseData<O365ReroutingConnectionDomain>>(`/azure/rerouting/domain/${id}`, data)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * List of domain
   * @param queryObj - Object of `IQueryObj`
   * @returns Domain list with count. Object of `O365ReroutingConnectionDomainListWithCount`
   */
  getReroutingConnectionDomainList(queryObj?: IQueryObj): Observable<O365ReroutingConnectionDomainListWithValidation> {
    return this.http
      .get<
        ResponseData<O365ReroutingConnectionDomainListWithCount>
      >(`/azure/rerouting/domain${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(
        this.operator.extractResponseData(),
        map(data => ({
          ...data,
          rows: data.rows.map(row => ({
            ...row,
            isBlocked: intersects(BLOCKED_DOMAIN_LIST, row.staticRoutingServer)
          }))
        }))
      );
  }

  /**
   * Getting domain details
   * @param id - Id of domain
   * @returns Domain details that object of `O365ReroutingConnectionDomain`
   */
  getReroutingConnectionDomainDetails(id: string): Observable<O365ReroutingConnectionDomain> {
    return this.http
      .get<ResponseData<O365ReroutingConnectionDomain>>(`/azure/rerouting/domain/${id}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Remove the domain
   * @param id - Id of domain
   * @returns - Object of `O365ReroutingConnectionDomain`
   */
  deleteReroutingConnectionDomain(id: string): Observable<O365ReroutingConnectionDomain> {
    return this.http
      .delete<ResponseData<O365ReroutingConnectionDomain>>(`/azure/rerouting/domain/${id}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get extension properties for the directory attribute within the given connection
   * @param syncInfoId - Id of connection
   * @returns Array of extension properties
   */
  getAvailableExtensionProperties(syncInfoId: number): Observable<O365ExtensionProperty[]> {
    return this.http
      .post<IResponseData<O365ExtensionProperty[]>>(`/azure/getAvailableExtensionProperties`, { syncInfoId })
      .pipe(this.operator.extractResponseData());
  }
}
