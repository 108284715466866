import { IWizardTabComponent } from 'src/app/model/interfaces/wizard.interface';
export const RE_ROUTING_SETUP_WIZARD_STEP: IWizardTabComponent[] = [
  {
    isFirstTab: true,
    open: true,
    stepNo: 1,
    tabTitle: 'rerouting_setup_createconnection_hl'
  },
  {
    classContent: 'bannerGraphic',
    stepNo: 2,
    tabTitle: 'rerouting_setup_connectioninfo_hl'
  },
  {
    completeFollowingText: 'complete_following_steps_to_create_campaign',
    isLastStep: true,
    stepNo: 3
  }
];

export const ADD_DOMAIN_WIZARD_STEP: IWizardTabComponent[] = [
  {
    isFirstTab: true,
    open: true,
    stepNo: 1,
    tabTitle: 'rerouting_setup_domainvalue_hl'
  },
  {
    classContent: 'bannerGraphic',
    stepNo: 2,
    tabTitle: 'rerouting_setup_sigplacement_hl'
  },
  {
    classContent: 'bannerGraphic',
    stepNo: 3,
    tabTitle: 'rerouting_combined_signatures_hl'
  },
  {
    classContent: 'bannerGraphic',
    stepNo: 4,
    tabTitle: 'rerouting_setup_staticroutserver_hl'
  },
  {
    completeFollowingText: 'complete_following_steps_to_create_campaign',
    isLastStep: true,
    stepNo: 5
  }
];

export const BLOCKED_DOMAIN_LIST: string[] = ['blocked-by-mailtastic.mail.protection.outlook.com'];
